import React from "react";

export const Footer = (props) => {
  return (
    <footer className="footer-sec mt-5">
      <div className="container col-lg-12">
        <div className="row main">
          <div className="col-lg-4 copyright">
            <p>© Copyright 2022 XetaOps.</p>
          </div>
          <div className="col-lg-4 logo p-3">
            <div className="footer-header">
              <svg
                xmlSpace="preserve"
                viewBox="0 0 100 100"
                y={0}
                x={0}
                xmlns="http://www.w3.org/2000/svg"
                width="30px"
                height="30px"
                style={{
                  width: "10%",
                  height: "100%",
                  backgroundSize: "initial",
                  backgroundRepeatY: "initial",
                  backgroundRepeatX: "initial",
                  backgroundPositionY: "initial",
                  backgroundPositionX: "initial",
                  backgroundOrigin: "initial",
                  backgroundImage: "initial",
                  backgroundClip: "initial",
                  backgroundAttachment: "initial",
                  animationPlayState: "paused",
                  transform: "rotate(-30deg)",
                }}
                {...props}
              >
                <g
                  className="ldl-scale"
                  style={{
                    transformOrigin: "50% 50%",
                    animationPlayState: "paused",
                  }}
                >
                  <path
                    d="M25.7 17.1L12.2 37.5h22.6l5.4-20.4z"
                    style={{
                      animationPlayState: "paused",
                    }}
                  />
                  <path
                    d="M74.3 17.1H59.8l5.4 20.4h22.6z"
                    fill="#1d0e0b"
                    style={{
                      animationPlayState: "paused",
                    }}
                  />
                  <path
                    d="M40.2 17.1l-5.4 20.4h30.4l-5.4-20.4z"
                    style={{
                      animationPlayState: "paused",
                    }}
                  />
                  <path
                    d="M12.2 37.5L50 82.9 34.8 37.5z"
                    style={{
                      animationPlayState: "paused",
                    }}
                  />
                  <path
                    d="M65.2 37.5L50 82.9l37.8-45.4z"
                    fill="#1d0e0b"
                    style={{
                      animationPlayState: "paused",
                    }}
                  />
                  <path
                    d="M50 37.5H34.8L50 82.9l15.2-45.4z"
                    style={{
                      animationPlayState: "paused",
                    }}
                  />
                  <path
                    d="M90 37.8V36.9c0-.1-.1-.2-.1-.3v-.1l-.1-.1c0-.1 0-.1-.1-.2L76.1 15.9c-.4-.6-1.1-1-1.8-1H25.7c-.7 0-1.4.4-1.8 1L10.3 36.3s0 .1-.1.2l-.1.1v.1c0 .1-.1.2-.1.3v.9c0 .1.1.2.1.3 0 .1.1.2.1.3v.1c0 .1.1.1.1.2s.1.1.1.2l37.8 45.5.3.3h.2c.1 0 .1.1.2.1h.1c.1 0 .2.1.2.1h.2c.2 0 .3.1.5.1s.3 0 .5-.1h.1s.1 0 .1-.1c.1 0 .2-.1.2-.1h.1c.1 0 .1-.1.2-.1h.2l.3-.3 37.8-45.5c0-.1.1-.1.1-.2s.1-.1.1-.2v-.1c0-.1.1-.2.1-.3.2 0 .3-.1.3-.3zM73.1 19.2l10.7 16.1H66.9l-4.3-16.1h10.5zm-31.2 0h16.2l4.3 16.1H37.6l2.2-8.3 2.1-7.8zm7 53.7L37.8 39.7h24.4l-.8 2.3L50 76.1l-1.1-3.2zm-22-53.7h10.5l-2.2 8.3-2.1 7.8H16.2l10.7-16.1zM16.8 39.7h16.4l11.1 33-27.5-33zm39 32.9l11.1-33h16.4l-27.5 33z"
                    style={{
                      animationPlayState: "paused",
                      background:
                        "linear-gradient(to right, #64ADF2, #C380FF  70%)",
                    }}
                    fill="#fff"
                  />
                </g>
              </svg>
              <span className="brand-name">XetaOps</span>
            </div>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-2 p-3">
            <h6 className="social-title">Follow Us</h6>
            <div className="social-media-icons">
            <a
              href="https://wa.me/971501788258"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-whatsapp icon"></i>
            </a>

            <a
              href="https://www.linkedin.com/company/xetaops/?trk=public_profile_topcard-current-company&originalSubdomain=ae"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-linkedin-in icon"></i>
            </a>

            <a
              href="https://www.instagram.com/xetaops/?igshid=YmMyMTA2M2Y%3D"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-instagram icon"></i>
            </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
