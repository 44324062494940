import React, { Suspense, useRef, useState } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { Diamond } from "./Diamond";
import { Points, PointMaterial } from "@react-three/drei";
import * as random from "maath/random/dist/maath-random.esm";
import { Physics } from "@react-three/cannon";

function Stars(props) {
  const ref = useRef();
  const [sphere] = useState(() =>
    random.inSphere(new Float32Array(5000), { radius: 5.5 })
  );
  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 25;
    ref.current.rotation.y -= delta / 25;
  });
  return (
    <group rotation={[2, 2, Math.PI / 2]}>
      <Points
        ref={ref}
        positions={sphere}
        stride={4}
        frustumCulled={false}
        {...props}
      >
        <PointMaterial
          transparent
          color="#fff"
          size={2.5}
          sizeAttenuation={false}
          depthWrite={false}
        />
      </Points>
    </group>
  );
}
export const LandingPage = () => {
  return (
    <>
      <section className="container landing-page">
        <div className="col-lg-12">
          <div className="row">
            {/* <div className="col-lg-4 content">
              <h1>Welcome To XetaOps.</h1>
              <h4>Who we are?</h4>
              <p>
                We offer best-in-class business technology solutions including
                custom app development to cater to your infrastructure growing
                demand.
              </p>
              <button className="landingPage-btn">Learn More</button>
            </div> */}
            <div className="col-lg-12">
              <Canvas>
                {/* <OrbitControls enableZoom={false} camera={false}/> */}
                <Stars />
                <Suspense fallback={null}>
                  <ambientLight intensity={0.5} />
                  <spotLight position={[100, 100, 40]} angle={2} castShadow />
                  <Diamond />
                </Suspense>
              </Canvas>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
