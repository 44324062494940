/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: maximlin (https://sketchfab.com/maksimlinchak006)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/3d-models/diamond-ultra-low-poly-model-fda3b5371f8940fbabffbc54d024dc63
title: Diamond ultra low poly model
*/

import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
import { LayerMaterial, Depth } from "lamina";

export const Diamond = (props) => {
  const { nodes, materials } = useGLTF("/diamond.gltf");
  return (
    <group {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 1.8, 0, 0]} scale={0.02}>
          <mesh
            geometry={nodes.Cylinder__0.geometry}
            material={materials["Scene_-_Root"]}
            rotation={[-Math.PI / 2, 100, 100]}
            scale={[100, 100, 65]}
          >
            <LayerMaterial
              color="#ffffff" //
              lighting="physical"
            >
              <Depth
                colorA="#c380ff" //
                colorB="#64adf2"
                alpha={1.1}
                mode="multiply"
                near={5}
                far={3}
                origin={[3.5, 2, 1]}
              />
            </LayerMaterial>
            </mesh>
        </group>
      </group>
    </group>
  );
};

useGLTF.preload("/diamond.gltf");
