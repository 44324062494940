import { React } from "react"
import Card from "react-bootstrap/Card";

export const DevelopmentRoadmap = (props) => { 

  return (
    <section className="container services" id="services">
      <div className="background"></div>
      <div className="services-heading col-lg-12 mt-2">
        <h2 className="roadmap-title text-end mb-4">Development Roadmap </h2>
        {/* Row 1 */}
        <div className="col-lg-12">
          <div className="row">
            <div className="development-card col-lg-4 d-flex justify-content-center">
              <Card className="cards-roadmap mb-2">
                <Card.Header className="card-header">
                <div className="card-title">
                  01 <span className="mx-2">Product Design</span>
                </div>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>Discovery Workshop</li>
                      <li>Custom Applications</li>
                      <li>Product Design</li>
                      <li>UI\UX Design</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
        {/* Row 2 */}
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4 d-flex justify-content-center">
              <Card className="mb-2 cards-roadmap">
                <Card.Header>
                  <div className="card-title">
                  02 <span className="mx-2">Product Development</span>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>Front-End and Back-End ( Web & Mobile )</li>
                      <li>Custom Software Development</li>
                      <li>IOT (Internet of Things)</li>
                      <li>Data Science and AI</li>
                      <li>CMS Hosting and Development</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
        {/* Row 3 */}
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4"></div>
            <div className="col-lg-4 d-flex justify-content-center">
              <Card className="mb-2 cards-roadmap">
                <Card.Header>
                  <div className="card-title">
                  03 <span className="mx-2">Product Launch</span>
                  </div>
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <ul>
                      <li>QA Testing</li>
                      <li>DevOps</li>
                      <li>Cyber Security</li>
                      <li>Support and Maintenance</li>
                    </ul>
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
