import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { LandingPage } from "./Components/LandingPage";
import { DevelopmentRoadmap } from "./Components/DevelopmentRoadmap";
import { Header } from "./Components/Header";
import { Cursor } from "./Components/Cursor";
import { LeftSideBar } from "./Components/LeftSideBar";
import { Banner } from "./Components/Banner";
import { Overlay } from "./Components/Overlay";
import { DevopsServices } from "./Components/DevopsServices";
import { OtherServices } from "./Components/OtherServices";
import { Footer } from "./Components/Footer";
import { ContactUs } from "./Components/ContactUs";
import { About } from "./Components/About";





function App() {
  return (
    <div style={{ minHeight: `calc(100vh - 475px)` }}>
      {/* <Cursor /> */}
      <Header />
      <LandingPage />
      <Overlay />
      <LeftSideBar />
      <Banner />
      {/* <About/> */}
      <DevelopmentRoadmap />
      <DevopsServices />
      <OtherServices />
        <ContactUs />
      <Footer />
    </div>
  );
}

export default App;
