import React from "react";

export const LeftSideBar = () => {
  return (
    <div className="text-rotate">
      <a href="mailto:hello@xetaops.com">hello@xetaops.com</a>

    </div>
  );
};
