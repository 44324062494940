import React from "react";
import { InlineWidget } from "react-calendly";

export const ContactUs = () => {
  return (
    <section className="container col-lg-12 mt-3" id="contact">
      <h2 className="roadmap-title text-end">Contact-Us</h2>
      <div className="row">
        <div className="col-lg-6 mb-5">
          <div className="contact-us">
            {/* <img
              className="contact-us-image w-100 h-100"
              src="/images/Group-132-blur.png"
              alt=""
            /> */}
            <div class="spinner">
              <div class="spinner1"></div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 contact-text">
          <p className="contactpage-content">
            We bring your ideas to life! Book a meeting and let's work
            together.
          </p>
          <a
            href="https://calendly.com/cloud-consultation/30min?month=2023-01"
            target="_blank"
            className="contact-button mt-5"
          >
            Book a Meeting
          </a>
        </div>
      </div>
    </section>
  );
};
