import React from "react";

export const Overlay = () => {
  return (
    <div
      className="container overlay-container" >
      <h1 className="col-lg-12 overlay-heading">
        Design. Develop. Launch.
      </h1>
      <p className="col-lg-4 mt-5">
        We offer best-in-class business technology solutions including custom
        app development to cater to your infrastructure growing demand.
      </p>
    </div>
  );
};
